var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('text-label',{attrs:{"text":_vm.label}}):_vm._e(),_c('div',{staticClass:"mt-custom-select",class:[
    _vm.className,
    ("custom-select--" + _vm.size),
    {
      'custom-select--datepicker': _vm.datepicker,
      'custom-select--hide-caret': !_vm.caret
    }
  ]},[_c('v-select',{attrs:{"options":_vm.options,"value":_vm.value,"placeholder":_vm.placeholder,"multiple":_vm.value instanceof Array,"clearable":false,"selectable":function (option) { return !option.isParent && !option.disabled },"disabled":_vm.disabled,"append-to-body":"","calculate-position":_vm.withPopper},on:{"input":_vm.changedValue},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var isParent = ref.isParent;
  var label = ref.label;
return [(isParent)?_c('div',{staticClass:"parent"},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()]}},(_vm.caret)?{key:"open-indicator",fn:function(ref){
  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('svg',{attrs:{"width":"12","height":"22","viewBox":"0 0 12 22","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 3.5L4.5 11L12 18.5L10.5 21.5L0 11L10.5 0.5L12 3.5Z","fill":_vm.getCaretColor}})])])]}}:null],null,true)}),(_vm.datepicker)?_c('img',{staticClass:"calendar-icon",attrs:{"src":require("@/assets/icons/calendar.svg"),"alt":"calendar"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }