<template>
  <div>
    <text-label v-if="label" :text="label"></text-label>
    <div class="mt-custom-select" :class="[
      className,
      `custom-select--${size}`,
      {
        'custom-select--datepicker': datepicker,
        'custom-select--hide-caret': !caret
      }
    ]"
    >
      <v-select
        :options="options"
        :value="value"
        :placeholder="placeholder"
        :multiple="value instanceof Array"
        :clearable="false"
        @input="changedValue"
        :selectable="option => { return !option.isParent && !option.disabled }"
        :disabled="disabled"
        append-to-body
        :calculate-position="withPopper"
      >
        <template #option="{ isParent, label }">
          <div v-if="isParent" class="parent">
            {{ label }}
          </div>
        </template>
        <template #open-indicator="{ attributes }" v-if="caret">
          <span v-bind="attributes">
          <svg
            width="12"
            height="22"
            viewBox="0 0 12 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.5L4.5 11L12 18.5L10.5 21.5L0 11L10.5 0.5L12 3.5Z"
              :fill="getCaretColor"
            />
          </svg>
        </span>
        </template>
      </v-select>
      <img v-if="datepicker" src="@/assets/icons/calendar.svg" class="calendar-icon" alt="calendar" />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import TextLabel from './TextLabel'
import { createPopper } from '@popperjs/core'

export default {
  name: 'CustomSelect',

  components: {
    TextLabel,
    vSelect
  },

  data() {
    return {
      placement: 'bottom-start'
    }
  },

  props: {
    value: {
      required: false,
      type: [Object, Array]
    },
    options: {
      required: true,
      type: Array
    },
    placeholder: {
      type: String,
      default: ''
    },
    datepicker: {
      type: Boolean,
      default: false
    },
    caret: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    label: {
      required: false,
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    }
  },

  computed: {
    getCaretColor() {
      if (this.className === 'blue') {
        return '#7987FF'
      }

      return '#191919'
    }
  },

  methods: {
    changedValue(event) {
      this.$emit('input', event)
    },

    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top-start'
              )
            }
          }
        ]
      })

      return () => popper.destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-custom-select {
  position: relative;

  ::v-deep {
    .v-select {
      min-height: 44px;

      .vs__dropdown-toggle {
        min-height: 44px;
        border-color: $color-grey;
      }

      &.vs--open .vs__open-indicator {
        transform: none;
      }

      .vs__dropdown-menu {
        min-width: 100%;
        width: auto;
      }
    }

    .vs__open-indicator {
      svg {
        margin-left: 5px;
        transform: rotate(-90deg);
        width: 14px;
        height: 10px;
      }
    }

    .vs__clear {
      display: none;
    }

    .vs__dropdown-toggle {
      padding: 6px 8px 10px;
    }

    .vs--single.vs--open .vs__selected,
    .vs--single.vs--loading .vs__selected {
      position: relative;
    }

    .vs--single.vs--searching .vs__selected {
      display: flex;
      visibility: hidden;
    }

    .vs__selected {
      padding: 0;
      color: #000;
      border: 0;
      position: relative;
      z-index: 2;

      + .vs__search {
        position: absolute;
        color: #000;
        width: 100%;
        height: 21px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }

    .vs--multiple {
      .vs__selected {
        background-color: transparent;
        color: #000;
        padding: 0;

        + .vs__search {
          width: 1px;
          position: initial;
        }
      }
    }

    .vs__deselect {
      margin-left: 8px;
      fill: #000;
    }

    .vs__search {
      padding: 0;
      color: #ccc;
    }

    .vs--searching {
      .vs__search {
        color: #000;
      }
    }
  }

  &.blue {
    ::v-deep {
      .v-select {
        .vs__dropdown-toggle {
          background-color: #fff;
          border-color: #7987FF;
        }
      }

      .vs__selected {
        color: #7987FF;
      }
    }
  }
}

.custom-select--datepicker {
  &::v-deep {
    .vs__selected {
      background: none;
      padding: 0;
    }

    .vs__selected + .vs__search {
      height: 100%;
      position: absolute;
      width: 100%;
      margin-top: 2px;
      margin-left: 2px;
      line-height: 1.15;
    }

    .v-select .vs__dropdown-toggle {
      padding-right: 41px;
    }
  }
}

.custom-select--hide-caret {
  ::v-deep {
    .vs__actions {
      display: none;
    }
  }
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: auto;
  transform: translateY(-50%);
  pointer-events: none;
}

.parent {
  font-weight: bold;
  pointer-events: none;
  color: #333;
}

.custom-select--large {
  &::v-deep {
    .v-select .vs__dropdown-toggle {
      min-height: 49px;
    }

    .vs__selected + .vs__search {
      height: 27px;
    }
  }
}
</style>
