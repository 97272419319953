<template>
  <div class="text-label">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'TextLabel',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.text-label {
  color: #191919;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
</style>
